import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getBlogListingDataSuccess: ["response"],
  getBlogListingDataFailure: ["error"],
  getBlogListingDataRequest: ["data"],

  setBlogListingState: ["key", "value"],
});

export const BlogListingTypes = Types;

export const getBlogListingAction = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  blogListingData: null,
};

export const getBlogListingDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getBlogListingDataSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      blogData: response.data?.pageManagementOrLatestUpdates,
      isLoading: false,
    };
  }
};

export const getBlogListingDataFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setBlogListingState = (state: any, action: any) => {
    return {
        ...state,
        [action.key]: action.value, 
      };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_BLOG_LISTING_DATA_REQUEST]: getBlogListingDataRequest,
  [Types.GET_BLOG_LISTING_DATA_SUCCESS]: getBlogListingDataSuccess,
  [Types.GET_BLOG_LISTING_DATA_FAILURE]: getBlogListingDataFailure,
  // [Types.SET_BLOG_LISTING_DATA_STATE]: setBlogListingState,
});
