import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { getBlogListingAction } from "../../redux-container/blog-listing-redux/blogListingRedux";

import { call, put } from "redux-saga/effects";

export function* getBlogListingSaga(api: any,action: any): SagaIterator {
  try {
    // const response = yield call(api.getPreLoginPageDetails, action.data);
    const response = yield call(api.getAllInsights,action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(getBlogListingAction.getBlogListingDataSuccess(response.data));
    } else {
      yield put(getBlogListingAction.getBlogListingDataFailure(response.data));
    }
  } catch (error) {
    yield put(getBlogListingAction.getBlogListingDataFailure(error));
  }
}