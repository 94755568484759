import React from "react";
import clevertap from "clevertap-web-sdk";

const useClevertap = () => {
  // TO PUSH EVENT TO CLEVERTAP
  const clevertapPushEvent = (
    eventName: string,
    eventData: { [key: string]: any }
  ) => {
    console.log(eventData,'some event data')
    clevertap.event.push(eventName, eventData)
  };

  // TO LOGIN USER TO CLEVERTAP
  const clevertapLoginUser = (userData: { [key: string]: any }) => {
    clevertap.onUserLogin.push({
      Site: userData,
    });
  };

  // UPDATE USER OF CLEVERTAP
  const clevertapUpdateUser = (userData: { [key: string]: any }) => {
    clevertap.profile.push({
      Site: userData,
    });
  };

  // WEB PUSH NOTIFICATION
  const clevertapNotification = () => {
    clevertap.notifications.push({
      titleText: "Would you like to receive notifications from us?",
      bodyText:
        "We promise to only send you updates on your transactions and useful content.",
      okButtonText: "Sign me up!",
      rejectButtonText: "No, thanks.",
      okButtonColor: "#000000",
      askAgainTimeInSeconds: 5,
      serviceWorkerPath: "./clevertap_sw.js",
      subscriptionCallback: function () {
        console.log("user subscribed for web push notifications");
      },
      rejectCallback: function () {
        console.log("reject Callback");
      },
      okCallback: function () {
        console.log("ok Callback");
      },
    });
    console.log("Permission popup prompted");
  };

  return { clevertapPushEvent, clevertapLoginUser, clevertapUpdateUser, clevertapNotification };
};

export default useClevertap;

