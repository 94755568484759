interface Item {
    id?: string;
    price?: string;
    quantity?: number;
    crmLaunchName?: string;
}
interface CriteoTagData {
    email?: string;
    items?: Item[];
    item?:string;
    transactionId?: string;

}
type TagType = "home" | "viewItem" | "addToCart" | "trackTransaction";
interface Window {
    criteo_q: Array<{
        event: string;
        [key: string]: any;
    }>;
}
const deviceType = /iPad/.test(navigator.userAgent)
    ? "t"
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
        ? "m"
        : "d";

export const addCriteoTag = (tagType: TagType, data: CriteoTagData = {}): void => {
    window.criteo_q = window.criteo_q || [];
    const baseData = [
        { event: "setAccount", account: 117043 },
        { event: "setEmail", email: data.email || "Email Address of User" },
        { event: "setSiteType", type: deviceType },
    ];
    let specificData = [];
    switch (tagType) {
        case 'home':
            specificData = [{ event: "viewHome" }];
            break;

        case "addToCart":
            specificData = [
                {
                    event: "addToCart",
                    item: data.items || [],
                },
            ];
            break;
        //on download catalogue and book now (pdp page and why to invest page)
        case "viewItem":
            specificData = [
                {
                    event: "viewItem",
                    item: data.item,

                },
            ];
            break;
        case "trackTransaction":
            specificData = [
                {
                    event: "trackTransaction",
                    // id: data.transactionId || "Transaction ID",
                    item: data.items || [],
                },
            ];
            break;
        default:
            return;
    }
    window.criteo_q.push(...baseData, ...specificData);
};