import { CssBaseline, ThemeProvider } from "@mui/material";
// import MuiActionStatusDialog from "components/mui-dialogs/MuiActionStatusDialog";
import { Fragment, useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppRoutes from "routes/AppRoutes";
import { RouteConfigurations } from "routes/RouteConfigurations";
import OverridedThemes from "./mui-themes/ThemeOverrides";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseRunCleverTapEvents from "app/hooks/UseRunCleverTapEvents";
import useClevertap from "app/hooks/useClevertap";
import Loader from "components/loader/Loader";
import { PageOneActions } from "redux-container/page1data-get-redux/getPage1data";
// import { getPreLoginPageAction } from "redux-container/pre-login-page-management-redux/PreLoginPageManagementRedux";
// import { PageTypeEnum } from "enumerations/PageTypeEnum";

//<------------------------comment starts------------------------>
//commneted as not required for current flow (No login flow - 24.09.24)
// import { useLocation, useNavigate } from "react-router-dom";
// import GenerateOtpActions from "redux-container/login/generate-otp/GenerateOtpRedux";
// import VerifyOtpActions from "redux-container/login/verify-otp/VerifyOtpRedux";
// import NewUserNameInfoActions from "redux-container/login/new-user-name-info/NewUserNameInfoRedux";
// import { getItemFromLocalStorage } from "utils/LocalStorageUtils";

// import "react-quill/dist/quill.core.css";
// import "react-quill/dist/quill.snow.css";
// import "react-quill/dist/quill.bubble.css";

// <------------------------comments ends------------------------>
const actionDispatch = (dispatch: any) => {
  return {
    // resetGenerateOtpState: () =>
    //   dispatch(GenerateOtpActions.resetGenerateOtpState()),
    // resetVerifyOtpState: () => dispatch(VerifyOtpActions.resetVerifyOtpState()),
    // resetNewUserNameInfoState: () =>
    //   dispatch(NewUserNameInfoActions.resetNewUserNameInfoState()),
    getPageOneData: (params: any) =>
      dispatch(PageOneActions.getPageOneDataRequest(params)),
  };
};

const App = () => {
  //<------------------------commented code------------------------>
  //commneted as not required for current flow (No login flow - 24.09.24)
  // const navigate = useNavigate();
  // const { pathname } = useLocation();
  // const isLoggedIn = useSelector((state: any) => state?.verifyOtp.isLoggedIn);
  // const isLoggedInAfterNewUsername = useSelector(
  //   (state: any) => state?.newUserNameInfo.isLoggedIn
  // );

  const {
    //   resetGenerateOtpState,
    //   resetVerifyOtpState,
    //   resetNewUserNameInfoState,
    getPageOneData,
  } = actionDispatch(useDispatch());
  
  // <------------------------comments code------------------------>
  // const isSessionExpired = getItemFromLocalStorage("isSessionExpired");
  // useEffect(() => {
  //   if (pathname === RouteConfigurations.login || pathname === "/") {
  //     navigate(RouteConfigurations.home);
  //   }
  // }, [isLoggedIn, isLoggedInAfterNewUsername, isSessionExpired]);

  // const handleLogout = () => {
  //   localStorage.clear();
  //   resetGenerateOtpState();
  //   resetVerifyOtpState();
  //   resetNewUserNameInfoState();
  //   navigate(RouteConfigurations?.login);
  // };

  const { clevertapNotification } = useClevertap();
  useEffect(() => {
    clevertapNotification();
    getPageOneData("5001");
  }, []);

  UseRunCleverTapEvents();

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={OverridedThemes}>
        {/* <MuiActionStatusDialog
          maxWidth={"sm"}
          issuccess={false}
          isconfirmdialog={true}
          responsemessage={"Alert"}
          message={"Your Session has expired!"}
          open={isSessionExpired == "true" ? true : false}
          handleclose={handleLogout}
        /> */}
        <Suspense fallback= {<div style={{height:'100vh', width:'100vw',display:'flex', justifyContent:'center', alignItems:'center'}}><Loader/></div>}>
          <AppRoutes />
        </Suspense>
        <ToastContainer />
      </ThemeProvider>
    </Fragment>
  );
};

export default App;
