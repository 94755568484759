import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import configureStore from "./CreateStore";
import rootSaga from "../sagas";

export const reducers = combineReducers({
  generateOtp: require("./login/generate-otp/GenerateOtpRedux").reducer,
  verifyOtp: require("./login/verify-otp/VerifyOtpRedux").reducer,
  newUserNameInfo: require("./login/new-user-name-info/NewUserNameInfoRedux")
    .reducer,
  troubleSignin:
    require("./login/trouble-signingin-redux/TroubleSigninginRedux").reducer,
  pageManagement: require("./page-management-redux/PageManagementRedux")
    .reducer,
  preLoginPageManagement:
    require("./pre-login-page-management-redux/PreLoginPageManagementRedux")
      .reducer,
  referYourFriend: require("./refer-your-friend/ReferYourFriendRedux").reducer,
  insights: require("./insights-redux/InsightsRedux").reducer,
  testimonials: require("./testimonials/TestimonialsRedux").reducer,
  updates: require("./updates/UpdatesRedux").reducer,
  notifications: require("./notifications/NotificationsRedux").reducer,
  projectContent: require("./project-contents/ProjectContents").reducer,
  profile: require("./profile/ProfileRedux").reducer,
  portfolio: require("./portfolio-redux/PortfolioRedux").reducer,
  newLaunch: require("./investments/new-launch/NewLaunchRedux").reducer,
  investmentDetails:
    require("./investments/investment-details/InvestmentDetailsRedux").reducer,
  securityTips: require("./profile/SecurityTipsRedux").reducer,
  documents: require("./documents/DocumentsRedux").reducer,
  userManagement: require("./user-management/UserManagementRedux").reducer,
  chat: require("./chat-redux/ChatRedux").reducer,
  search: require("./search/SearchRedux").reducer,
  editProfile: require("./profile/EditProfileRedux").reducer,
  privacyPolicy: require("./privacy-policy/PrivacyPolicy").reducer,
  watchList: require("./watch-list/watchListRedux").reducer,
  landSkuList: require("./investments/land-sku-list/LandSkuListRedux").reducer,
  commingSoon: require("./comming-soon/CommingSoon").reducer,
  pageSeo: require("./page-seo/PageSeo").reducer,
  mediaAndPrs: require("./media-and-pr/MediaAndPrRedux").reducer,
  pageOneData: require("./page1data-get-redux/getPage1data").reducer,
  blogListingData: require("./blog-listing-redux/blogListingRedux").reducer,
});

export default () => {
  const finalReducers = persistReducer(
    {
      key: "root",
      storage,
    },
    reducers
  );

  const { store } = configureStore(finalReducers, rootSaga);
  const persistor = persistStore(store);
  return { store, persistor };
};
