import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getPageOneDataSuccess: ["response"],
  getPageOneDataFailure: ["error"],
  getPageOneDataRequest: ["data"],

  setPageOneDataState: ["key", "value"],
});

export const PageOneTypes = Types;

export const PageOneActions = Creators;

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  pageOneData: null,
};

export const getPageOneDataRequest = (state: any) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getPageOneDataSuccess = (state: any, action: any) => {
  const response = action.response;
  if (response) {
    return {
      ...state,
      error: null,
      pageOneData: response.data,
      isLoading: false,
    };
  }
};

export const getPageOneDataFailure = (state: any, action: any) => {
  return {
    ...state,
    isLoading: false,
    error: action.error.message,
  };
};

export const setPageOneDataState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PAGE_ONE_DATA_REQUEST]: getPageOneDataRequest,
  [Types.GET_PAGE_ONE_DATA_SUCCESS]: getPageOneDataSuccess,
  [Types.GET_PAGE_ONE_DATA_FAILURE]: getPageOneDataFailure,

  [Types.SET_PAGE_ONE_DATA_STATE]: setPageOneDataState,
});

export {};